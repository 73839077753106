var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("seamless-access-button", {
        staticClass: "mb-4",
        attrs: { redirect: _vm.redirectTo + "/satosa" },
      }),
      _c(
        "google-oauth-btn",
        { attrs: { redirect: _vm.redirectTo + "/google" } },
        [_vm._v("Sign up with Google")]
      ),
      _c(
        "div",
        { staticClass: "d-flex align-center mt-5 mb-2" },
        [
          _c("v-divider"),
          _c("span", { staticClass: "caption px-2" }, [_vm._v("OR")]),
          _c("v-divider"),
        ],
        1
      ),
      _c("email-signup-form", {
        staticClass: "mt-4",
        attrs: {
          loading: _vm.sendingInvitation,
          emailDomainWhitelist: _vm.emailDomainWhitelist,
        },
        on: { verifiedEmail: _vm.signup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }